export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0"},{"name":"google-site-verification","content":"szzsVz8dBBIEHTD3Qtt3G7VRzXeTBkWXNovGw1vrT1Q","tagPriority":"low"},{"name":"build-version","content":"20241114.2","tagPriority":"low"},{"name":"probely-verification","content":"22382784-482f-4631-866e-ece8e6df72f5","tagPriority":"low"},{"property":"og:site_name","content":"Plataforma Tierra"},{"property":"og:title","content":"Plataforma Tierra"},{"name":"image","content":"https://static.penumbracosmica.earth/strapi-uploads/assets/ogimage_logo_tierra_55229a170f"},{"property":"og:image","content":"https://static.penumbracosmica.earth/strapi-uploads/assets/ogimage_logo_tierra_55229a170f","tagPriority":"low"},{"name":"description","content":"Una iniciativa del Grupo Cajamar para la digitalización y la sostenibilidad del sector agroalimentario"},{"property":"og:description","content":"Una iniciativa del Grupo Cajamar para la digitalización y la sostenibilidad del sector agroalimentario"},{"property":"og:url","content":"https://www.penumbracosmica.earth"}],"link":[{"rel":"preload","as":"image","href":"/logo-tierra.svg","tagPriority":"high"},{"rel":"icon","type":"image/x-icon","href":"/favicon.ico","tagPriority":"low"},{"rel":"“alternate”","hreflang":"x-default","href":"https://www.penumbracosmica.earth","tagPriority":"low"},{"rel":"“alternate”","hreflang":"es","href":"https://www.penumbracosmica.earth","tagPriority":"low"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"es"},"title":"Plataforma Tierra"}

export const appPageTransition = {"name":"page"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false