import { default as accesibilidadtUFHiOsx0nMeta } from "/azp/_work/1/s/pages/accesibilidad.vue?macro=true";
import { default as _91slug_93i6Wr9UlF6MMeta } from "/azp/_work/1/s/pages/actualidad/[slug].vue?macro=true";
import { default as indexag9EV7iyRnMeta } from "/azp/_work/1/s/pages/actualidad/index.vue?macro=true";
import { default as actualizar_45usuario0dfojYjk6zMeta } from "/azp/_work/1/s/pages/actualizar-usuario.vue?macro=true";
import { default as _91slug_93uJnSgOZYhsMeta } from "/azp/_work/1/s/pages/autor/[slug].vue?macro=true";
import { default as aviso_45legalCvr3h6KcdBMeta } from "/azp/_work/1/s/pages/aviso-legal.vue?macro=true";
import { default as centros_45experimentalesGUFQQDOpvqMeta } from "/azp/_work/1/s/pages/centros-experimentales.vue?macro=true";
import { default as _91slug_93pvMvSNb0WOMeta } from "/azp/_work/1/s/pages/comunidad/[blog]/[slug].vue?macro=true";
import { default as index0K2WlKY1OPMeta } from "/azp/_work/1/s/pages/comunidad/[blog]/index.vue?macro=true";
import { default as indexT6VOgrxACHMeta } from "/azp/_work/1/s/pages/comunidad/autores/index.vue?macro=true";
import { default as indexfaMwUuiby4Meta } from "/azp/_work/1/s/pages/comunidad/index.vue?macro=true";
import { default as condiciones_45de_45uso3siJpRPmCtMeta } from "/azp/_work/1/s/pages/condiciones-de-uso.vue?macro=true";
import { default as cuaderno_45de_45explotacion_45cxtcCJG7pR1f1Meta } from "/azp/_work/1/s/pages/cuaderno-de-explotacion-cxt.vue?macro=true";
import { default as _91slug_93NmcdjIy8rSMeta } from "/azp/_work/1/s/pages/encuesta/[slug].vue?macro=true";
import { default as establecer_45contrasenafo4kwu6ZzRMeta } from "/azp/_work/1/s/pages/establecer-contrasena.vue?macro=true";
import { default as _91slug_93toJt0poizsMeta } from "/azp/_work/1/s/pages/formacion/[slug].vue?macro=true";
import { default as indexzhh1QC1iFIMeta } from "/azp/_work/1/s/pages/formacion/index.vue?macro=true";
import { default as gestion_45autenticacionemvFfHQDVNMeta } from "/azp/_work/1/s/pages/gestion-autenticacion.vue?macro=true";
import { default as guardadosQveWUSqilmMeta } from "/azp/_work/1/s/pages/guardados.vue?macro=true";
import { default as _91slug_9312LG3hIkIiMeta } from "/azp/_work/1/s/pages/herramientas/[slug].vue?macro=true";
import { default as cxtierra2Z2SAUDSLQMeta } from "/azp/_work/1/s/pages/herramientas/cxtierra.vue?macro=true";
import { default as indexy3JMgRhW9RMeta } from "/azp/_work/1/s/pages/index.vue?macro=true";
import { default as _91slug_93DIpLoXS0H4Meta } from "/azp/_work/1/s/pages/innovacion/[slug].vue?macro=true";
import { default as indexFznYeZZaEuMeta } from "/azp/_work/1/s/pages/innovacion/index.vue?macro=true";
import { default as _91slug_93rxpCzWSUUmMeta } from "/azp/_work/1/s/pages/invita/[slug].vue?macro=true";
import { default as _91slug_93cZ4Js2Kn3KMeta } from "/azp/_work/1/s/pages/mercados/[slug].vue?macro=true";
import { default as _91slug_93lbHF904RXGMeta } from "/azp/_work/1/s/pages/mercados/actualidad/[slug].vue?macro=true";
import { default as indexx1ci6V1PpQMeta } from "/azp/_work/1/s/pages/mercados/index.vue?macro=true";
import { default as _91subsector_93BvAWDwt4W3Meta } from "/azp/_work/1/s/pages/mercados/sector/[sector]/subsector/[subsector].vue?macro=true";
import { default as mi_45startupUPAy5ixuKSMeta } from "/azp/_work/1/s/pages/mi-startup.vue?macro=true";
import { default as mis_45eventosYUYJh2DQjWMeta } from "/azp/_work/1/s/pages/mis-eventos.vue?macro=true";
import { default as perfil0kHnVqmdq6Meta } from "/azp/_work/1/s/pages/perfil.vue?macro=true";
import { default as politica_45de_45cookies_45cxtierra9yDxNYQCdyMeta } from "/azp/_work/1/s/pages/politica-de-cookies-cxtierra.vue?macro=true";
import { default as politica_45de_45cookiesSPHeEzJ8T1Meta } from "/azp/_work/1/s/pages/politica-de-cookies.vue?macro=true";
import { default as privacidaddwXnaMww1mMeta } from "/azp/_work/1/s/pages/privacidad.vue?macro=true";
import { default as _91slug_93KgYp9aK3GAMeta } from "/azp/_work/1/s/pages/proyecto/[slug].vue?macro=true";
import { default as publicacionesguaaRPrsOnMeta } from "/azp/_work/1/s/pages/publicaciones.vue?macro=true";
import { default as quienes_45somos1BC61c9iCAMeta } from "/azp/_work/1/s/pages/quienes-somos.vue?macro=true";
import { default as registroyl0eUgQ2feMeta } from "/azp/_work/1/s/pages/registro.vue?macro=true";
import { default as resultados_45busqueda7sqJYmoWcRMeta } from "/azp/_work/1/s/pages/resultados-busqueda.vue?macro=true";
import { default as index6PoyKGhdppMeta } from "/azp/_work/1/s/pages/startups/[convocatoria]/index.vue?macro=true";
import { default as inscripcionusx7nLjKexMeta } from "/azp/_work/1/s/pages/startups/[convocatoria]/inscripcion.vue?macro=true";
import { default as indexCeHhpXgFt7Meta } from "/azp/_work/1/s/pages/startups/index.vue?macro=true";
import { default as verificacion_45usuarioZhiHgLIATSMeta } from "/azp/_work/1/s/pages/verificacion-usuario.vue?macro=true";
import { default as _91slug_9391OJde5SNeMeta } from "/azp/_work/1/s/pages/visita-nuestros-centros-experimentales/[slug].vue?macro=true";
import { default as component_45stubpDT46sl3sbMeta } from "/azp/_work/1/s/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubpDT46sl3sb } from "/azp/_work/1/s/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "accesibilidad",
    path: "/accesibilidad",
    component: () => import("/azp/_work/1/s/pages/accesibilidad.vue").then(m => m.default || m)
  },
  {
    name: "actualidad-slug",
    path: "/actualidad/:slug()",
    component: () => import("/azp/_work/1/s/pages/actualidad/[slug].vue").then(m => m.default || m)
  },
  {
    name: "actualidad",
    path: "/actualidad",
    component: () => import("/azp/_work/1/s/pages/actualidad/index.vue").then(m => m.default || m)
  },
  {
    name: "actualizar-usuario",
    path: "/actualizar-usuario",
    meta: actualizar_45usuario0dfojYjk6zMeta || {},
    component: () => import("/azp/_work/1/s/pages/actualizar-usuario.vue").then(m => m.default || m)
  },
  {
    name: "autor-slug",
    path: "/autor/:slug()",
    component: () => import("/azp/_work/1/s/pages/autor/[slug].vue").then(m => m.default || m)
  },
  {
    name: "aviso-legal",
    path: "/aviso-legal",
    component: () => import("/azp/_work/1/s/pages/aviso-legal.vue").then(m => m.default || m)
  },
  {
    name: "centros-experimentales",
    path: "/centros-experimentales",
    component: () => import("/azp/_work/1/s/pages/centros-experimentales.vue").then(m => m.default || m)
  },
  {
    name: "comunidad-blog-slug",
    path: "/comunidad/:blog()/:slug()",
    component: () => import("/azp/_work/1/s/pages/comunidad/[blog]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "comunidad-blog",
    path: "/comunidad/:blog()",
    component: () => import("/azp/_work/1/s/pages/comunidad/[blog]/index.vue").then(m => m.default || m)
  },
  {
    name: "comunidad-autores",
    path: "/comunidad/autores",
    component: () => import("/azp/_work/1/s/pages/comunidad/autores/index.vue").then(m => m.default || m)
  },
  {
    name: "comunidad",
    path: "/comunidad",
    component: () => import("/azp/_work/1/s/pages/comunidad/index.vue").then(m => m.default || m)
  },
  {
    name: "condiciones-de-uso",
    path: "/condiciones-de-uso",
    component: () => import("/azp/_work/1/s/pages/condiciones-de-uso.vue").then(m => m.default || m)
  },
  {
    name: "cuaderno-de-explotacion-cxt",
    path: "/cuaderno-de-explotacion-cxt",
    meta: cuaderno_45de_45explotacion_45cxtcCJG7pR1f1Meta || {},
    component: () => import("/azp/_work/1/s/pages/cuaderno-de-explotacion-cxt.vue").then(m => m.default || m)
  },
  {
    name: "encuesta-slug",
    path: "/encuesta/:slug()",
    meta: _91slug_93NmcdjIy8rSMeta || {},
    component: () => import("/azp/_work/1/s/pages/encuesta/[slug].vue").then(m => m.default || m)
  },
  {
    name: "establecer-contrasena",
    path: "/establecer-contrasena",
    component: () => import("/azp/_work/1/s/pages/establecer-contrasena.vue").then(m => m.default || m)
  },
  {
    name: "formacion-slug",
    path: "/formacion/:slug()",
    component: () => import("/azp/_work/1/s/pages/formacion/[slug].vue").then(m => m.default || m)
  },
  {
    name: "formacion",
    path: "/formacion",
    component: () => import("/azp/_work/1/s/pages/formacion/index.vue").then(m => m.default || m)
  },
  {
    name: "gestion-autenticacion",
    path: "/gestion-autenticacion",
    meta: gestion_45autenticacionemvFfHQDVNMeta || {},
    component: () => import("/azp/_work/1/s/pages/gestion-autenticacion.vue").then(m => m.default || m)
  },
  {
    name: "guardados",
    path: "/guardados",
    component: () => import("/azp/_work/1/s/pages/guardados.vue").then(m => m.default || m)
  },
  {
    name: "herramientas-slug",
    path: "/herramientas/:slug()",
    component: () => import("/azp/_work/1/s/pages/herramientas/[slug].vue").then(m => m.default || m)
  },
  {
    name: "herramientas-cxtierra",
    path: "/herramientas/cxtierra",
    component: () => import("/azp/_work/1/s/pages/herramientas/cxtierra.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/azp/_work/1/s/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "innovacion-slug",
    path: "/innovacion/:slug()",
    component: () => import("/azp/_work/1/s/pages/innovacion/[slug].vue").then(m => m.default || m)
  },
  {
    name: "innovacion",
    path: "/innovacion",
    component: () => import("/azp/_work/1/s/pages/innovacion/index.vue").then(m => m.default || m)
  },
  {
    name: "invita-slug",
    path: "/invita/:slug()",
    meta: _91slug_93rxpCzWSUUmMeta || {},
    component: () => import("/azp/_work/1/s/pages/invita/[slug].vue").then(m => m.default || m)
  },
  {
    name: "mercados-slug",
    path: "/mercados/:slug()",
    component: () => import("/azp/_work/1/s/pages/mercados/[slug].vue").then(m => m.default || m)
  },
  {
    name: "mercados-actualidad-slug",
    path: "/mercados/actualidad/:slug()",
    component: () => import("/azp/_work/1/s/pages/mercados/actualidad/[slug].vue").then(m => m.default || m)
  },
  {
    name: "mercados",
    path: "/mercados",
    component: () => import("/azp/_work/1/s/pages/mercados/index.vue").then(m => m.default || m)
  },
  {
    name: "mercados-sector-sector-subsector-subsector",
    path: "/mercados/sector/:sector()/subsector/:subsector()",
    component: () => import("/azp/_work/1/s/pages/mercados/sector/[sector]/subsector/[subsector].vue").then(m => m.default || m)
  },
  {
    name: "mi-startup",
    path: "/mi-startup",
    meta: mi_45startupUPAy5ixuKSMeta || {},
    component: () => import("/azp/_work/1/s/pages/mi-startup.vue").then(m => m.default || m)
  },
  {
    name: "mis-eventos",
    path: "/mis-eventos",
    component: () => import("/azp/_work/1/s/pages/mis-eventos.vue").then(m => m.default || m)
  },
  {
    name: "perfil",
    path: "/perfil",
    component: () => import("/azp/_work/1/s/pages/perfil.vue").then(m => m.default || m)
  },
  {
    name: "politica-de-cookies-cxtierra",
    path: "/politica-de-cookies-cxtierra",
    component: () => import("/azp/_work/1/s/pages/politica-de-cookies-cxtierra.vue").then(m => m.default || m)
  },
  {
    name: "politica-de-cookies",
    path: "/politica-de-cookies",
    component: () => import("/azp/_work/1/s/pages/politica-de-cookies.vue").then(m => m.default || m)
  },
  {
    name: "privacidad",
    path: "/privacidad",
    component: () => import("/azp/_work/1/s/pages/privacidad.vue").then(m => m.default || m)
  },
  {
    name: "proyecto-slug",
    path: "/proyecto/:slug()",
    component: () => import("/azp/_work/1/s/pages/proyecto/[slug].vue").then(m => m.default || m)
  },
  {
    name: "publicaciones",
    path: "/publicaciones",
    component: () => import("/azp/_work/1/s/pages/publicaciones.vue").then(m => m.default || m)
  },
  {
    name: "quienes-somos",
    path: "/quienes-somos",
    component: () => import("/azp/_work/1/s/pages/quienes-somos.vue").then(m => m.default || m)
  },
  {
    name: "registro",
    path: "/registro",
    meta: registroyl0eUgQ2feMeta || {},
    component: () => import("/azp/_work/1/s/pages/registro.vue").then(m => m.default || m)
  },
  {
    name: "resultados-busqueda",
    path: "/resultados-busqueda",
    component: () => import("/azp/_work/1/s/pages/resultados-busqueda.vue").then(m => m.default || m)
  },
  {
    name: "startups-convocatoria",
    path: "/startups/:convocatoria()",
    component: () => import("/azp/_work/1/s/pages/startups/[convocatoria]/index.vue").then(m => m.default || m)
  },
  {
    name: "startups-convocatoria-inscripcion",
    path: "/startups/:convocatoria()/inscripcion",
    meta: inscripcionusx7nLjKexMeta || {},
    component: () => import("/azp/_work/1/s/pages/startups/[convocatoria]/inscripcion.vue").then(m => m.default || m)
  },
  {
    name: "startups",
    path: "/startups",
    component: () => import("/azp/_work/1/s/pages/startups/index.vue").then(m => m.default || m)
  },
  {
    name: "verificacion-usuario",
    path: "/verificacion-usuario",
    component: () => import("/azp/_work/1/s/pages/verificacion-usuario.vue").then(m => m.default || m)
  },
  {
    name: "visita-nuestros-centros-experimentales-slug",
    path: "/visita-nuestros-centros-experimentales/:slug()",
    component: () => import("/azp/_work/1/s/pages/visita-nuestros-centros-experimentales/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubpDT46sl3sbMeta?.name,
    path: "/formacion/las-ng-ts-como-herramienta-clave-para-un-agricultura-sostenible-en-la-union-europea",
    component: component_45stubpDT46sl3sb
  }
]