import revive_payload_client_4sVQNw7RlN from "/azp/_work/1/s/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/azp/_work/1/s/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/azp/_work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/azp/_work/1/s/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/azp/_work/1/s/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/azp/_work/1/s/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/azp/_work/1/s/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/azp/_work/1/s/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/azp/_work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/azp/_work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_hwlJ5PmHMh from "/azp/_work/1/s/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import templates_pwa_client_0e11bbe5_q2hwpPU87H from "/azp/_work/1/s/.nuxt/templates.pwa.client.0e11bbe5.ts";
import vueformPlugin_kEcARWMiqX from "/azp/_work/1/s/.nuxt/vueformPlugin.mjs";
import plugin_tbFNToZNim from "/azp/_work/1/s/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_RZVNejKJBe from "/azp/_work/1/s/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_ybZ5mczHdl from "/azp/_work/1/s/node_modules/nuxt-socket-io/lib/plugin.js";
import plugin_eTVJQYlCmx from "/azp/_work/1/s/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import nuxt_plugin_6wEQMY3tee from "/azp/_work/1/s/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import axios_sVCuMR6hEC from "/azp/_work/1/s/plugins/axios.js";
import cookiebot_client_nKv8AHmMrS from "/azp/_work/1/s/plugins/cookiebot.client.js";
import directives_GDwrTGmylM from "/azp/_work/1/s/plugins/directives.js";
import emitter_eH7MaP8C1y from "/azp/_work/1/s/plugins/emitter.js";
import flipCountdown_client_mQB3BMwUfI from "/azp/_work/1/s/plugins/flipCountdown.client.js";
import gmaps_wGGaTjIp1A from "/azp/_work/1/s/plugins/gmaps.js";
import hubspot_client_u1tlk0iImu from "/azp/_work/1/s/plugins/hubspot.client.js";
import i18n_sVHQBgnb3t from "/azp/_work/1/s/plugins/i18n.js";
import lazysizes_client_Jm9RdviRBo from "/azp/_work/1/s/plugins/lazysizes.client.js";
import modal_vQuRkj8BKT from "/azp/_work/1/s/plugins/modal.js";
import msal_xB62K6reFI from "/azp/_work/1/s/plugins/msal.js";
import scrollToSmoothly_client_tw1afmd4wO from "/azp/_work/1/s/plugins/scrollToSmoothly.client.js";
import social_zVIR4DDQPb from "/azp/_work/1/s/plugins/social.js";
import splide_client_tQKGW7GEcx from "/azp/_work/1/s/plugins/splide.client.js";
import veeValidate_nOzy97eFIK from "/azp/_work/1/s/plugins/veeValidate.js";
import vueInstantsearch_nenTLAGB3D from "/azp/_work/1/s/plugins/vueInstantsearch.js";
import vueTelInput_OfB3YnbU52 from "/azp/_work/1/s/plugins/vueTelInput.js";
import vuex_owYp5qnaH8 from "/azp/_work/1/s/plugins/vuex.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_hwlJ5PmHMh,
  templates_pwa_client_0e11bbe5_q2hwpPU87H,
  vueformPlugin_kEcARWMiqX,
  plugin_tbFNToZNim,
  plugin_client_RZVNejKJBe,
  plugin_ybZ5mczHdl,
  plugin_eTVJQYlCmx,
  nuxt_plugin_6wEQMY3tee,
  axios_sVCuMR6hEC,
  cookiebot_client_nKv8AHmMrS,
  directives_GDwrTGmylM,
  emitter_eH7MaP8C1y,
  flipCountdown_client_mQB3BMwUfI,
  gmaps_wGGaTjIp1A,
  hubspot_client_u1tlk0iImu,
  i18n_sVHQBgnb3t,
  lazysizes_client_Jm9RdviRBo,
  modal_vQuRkj8BKT,
  msal_xB62K6reFI,
  scrollToSmoothly_client_tw1afmd4wO,
  social_zVIR4DDQPb,
  splide_client_tQKGW7GEcx,
  veeValidate_nOzy97eFIK,
  vueInstantsearch_nenTLAGB3D,
  vueTelInput_OfB3YnbU52,
  vuex_owYp5qnaH8
]