import validate from "/azp/_work/1/s/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/azp/_work/1/s/middleware/auth.global.js";
import commons_45global from "/azp/_work/1/s/middleware/commons.global.js";
import manifest_45route_45rule from "/azp/_work/1/s/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  commons_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "registration-call-proposals": () => import("/azp/_work/1/s/middleware/registrationCallProposals.js")
}